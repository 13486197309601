import React, {useState, useEffect, useContext} from 'react';
import RestaurantFinder from '../APIs/RestaurantFinder';
import { RestaurantsContext } from '../context/RestaurantsContext';
import ScrollToTop from "react-scroll-to-top";
import Pagination from './Pagination';

const GuestList = () => {

  const {restaurants, setRestaurants} = useContext(RestaurantsContext)
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);
  
  //Load the posts
  useEffect(() =>{ 
    const FetchData = async () => {
      try{
        const response = await RestaurantFinder.get("/api/restaurants/");
        setRestaurants(response.data.data.restaurants);
      }catch(err){
        console.log(err);
      }
  };
  FetchData();
  },[]);
//Indexing for Pagination
const indexOfLastPost = currentPage * postsPerPage;
const indexOfFirstPost = indexOfLastPost - postsPerPage;
const currentPosts = restaurants.slice(indexOfFirstPost, indexOfLastPost);

//Change page
const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <section className="wrapper">
      <h1 className='text-light text-center'>Check out the places we've been</h1>
      <div className="container">
        <div className='row mt-n5'>
        {currentPosts?.map((restaurant) => {
          return (
            <div className="col-sm-12 col-md-6 col-lg-4 mb-4" key={restaurant.id}>
              <div className="card text-dark card-has-bg click-col" style={{backgroundImage: `url(${restaurant.imageUrl})`}}>
                  <div className="card-img-overlay d-flex flex-column">
                    <div className="card-body">
                      <small className="card-meta mb-2">{restaurant.area}</small>
                      <h2 className="card-title mt-0 ">{restaurant.restaurant_name}</h2>
                      <small><i className="far fa-map"></i> {restaurant.restaurant_address}</small>
                    </div>
                  <div className="card-footer">
                    <div className="media">
                      <div className="media-body">
                      <ul className='list-unstyled'>
                        <li><i className="fas fa-bolt mr-2 py-1"></i> Vibe: {restaurant.vibe_rate}</li>
                        <li><i className="fa-solid fa-hand-holding-heart mr-2 py-1"></i> Service: {restaurant.service_rate}</li>
                        <li><i className="fas fa-tag"></i> Price Range: {"$".repeat(restaurant.price_range)}</li>
                      </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
        <Pagination postsPerPage={postsPerPage} totalPosts={restaurants.length} currentPage={currentPage} paginate={paginate}/>
        </div>
      <ScrollToTop smooth />
    </div>
  </section>
  )
}

export default GuestList;

