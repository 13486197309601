import React, { useState, useContext } from 'react';
import RestaurantFinder from '../APIs/RestaurantFinder';
import { ItemsContext } from '../context/ItemsContext';
import { useParams } from 'react-router-dom';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ItemAdd = () => {

  const { id } = useParams();
  const {addItems} = useContext(ItemsContext);
  const [errors, setErrors] = useState({});
  const [dish, setDish] = useState("")
  const [review, setReview] = useState("")
  const [rating, setRating] = useState("")
  const [photo, setPhoto] = useState([])

  const photoSelected = (e) => {
    const photo = e.target.files[0]
    setPhoto(photo)
  }
  const clearState = () => {
    setDish("");
    setReview("");
    setRating("");
    setPhoto([]);
  }

  const handleSubmitItem = async (e) => {
    e.preventDefault();
    const validationErrors = {}
    //Error catching - This should be a function but it's going to be like this for now
    if(dish.trim() === "") {
      validationErrors.dish = "Restaurant name is required"
    }else if (dish.length > 50) {
      validationErrors.dish = "Name must be less than 50 Characters"
    }
    if(review.trim() === "") {
      validationErrors.review = "Restaurant name is required"
    }else if (review.length > 500) {
      validationErrors.review = "Name must be less than 50 Characters"
    }
    if(rating.trim() === ""){
      validationErrors.rating = "Please rate the service"
    }else if(rating <= 0 || rating > 9.9 ){
      validationErrors.rating = "Value must be from 0.1-9.9"
    }
    if(photo.length === 0) {
      validationErrors.photo = "Please add a photo so we can keep the site pretty :)"
    }
    setErrors(validationErrors);
    if(Object.keys(validationErrors).length === 0) {
      const spinner = toast.loading("Making Changes");
      try {
        const formData = new FormData();
        formData.append("dish", dish)
        formData.append("review", review)
        formData.append("rating", rating)
        formData.append("photo", photo)
        const response = await RestaurantFinder.post(`/api/restaurants/${id}/addItem`,formData, {headers: {'Content-Type': 'multipart/form-data'}})
        addItems(response.data.data.item);
        clearState();
        toast.update(spinner, {render: "Restaurant Updated, changes will show next time the page loads!",
          type: "success",
          isLoading: false,
          position: "top-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "dark",
    })
      }catch(err){
        console.log(err);
        toast.update(spinner, {render: "Something went wrong :(",
        type: "error",
        isLoading: false,
        position: "top-right",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "dark",
    })
      }
    }else{
      toast.error("Post failed, try again and review your errors!",{
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "dark",
      })
    }
  }
  
  return (
    /*Button to activate Modal Open to Input Dish Info*/
      <div className='my-2 text-center'>
        <button type="button" className="btn btn-lg btn-dark" data-bs-toggle="modal" data-bs-target="#itemAddModal">
        Review a Dish
        </button>
      {/*Start of modal*/} 
        <div className="modal fade" id="itemAddModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-black" id="staticBackdropLabel">Write your Review</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
      {/*Start of modal body*/}
              <div className="modal-body text-black">
      {/*Form Body start*/}
                <form action='upload' method='POST' onSubmit={handleSubmitItem} encType='multipart/form-data'>
                  <div className="row g-3">
                    <div className="col-md-6 text-start">
                      <label htmlFor="dishName">Dish Name</label>
                      <input value={dish} onChange={e => setDish(e.target.value)} type="text" className="form-control" id="dishName" autoComplete='off'></input>
                      {errors.dish && <span style={{color:'red'}}>{errors.dish}</span>}
                    </div>
                    <div id="restaurantNameHelpBlock" className="form-text text-start">
                      Maximum 50 Characters
                    </div>
                    <div className="col-md-12 text-start">
                      <label htmlFor="dishDescription">Dish Review</label>
                      <input value={review} onChange={e => setReview(e.target.value)} type="text" className="form-control" id="dishDescription" autoComplete='off'></input>
                      {errors.review && <span style={{color:'red'}}>{errors.review}</span>}
                    </div>
                    <div className="col-md-3 text-start">
                      <label htmlFor="dishRate">Rating / 10</label>
                      <input value={rating} onChange={e => setRating(e.target.value)} type="number" className="form-control" id="dishRate" min="0" max="9.9" step="0.1"></input>
                      {errors.rating && <span style={{color:'red'}}>{errors.rating}</span>}
                    </div>
                    <div className="col-md-12 text-start">
                      <label htmlFor="photoUpload">Upload a Photo</label>
                      <input defaultValue={photo} onChange={photoSelected} type="file" className="form-control" id="photoUpload"></input>
                      {errors.photo && <span style={{color:'red'}}>{errors.photo}</span>}
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="submit" className="btn btn-warning" data-bs-dismiss="modal">Add Review</button>
                  </div>
                </form>
      {/*Form Body End*/}
              </div>
      {/*End Modal Body*/}
            </div>
          </div>
        </div>
      </div>
  );
}
export default ItemAdd;
